import React, { useEffect, useState } from "react";
import { Seo } from "../components/seo";
import Layout from "../hoc/Layout/Layout";
import Booking from "../components/Booking/Booking";
import Instructions from "../components/Instructions/Instructions";

// markup
const IndexPage = () => {
  const [bookingActive, setBookingActive] = useState(false);
  const [serviceChosen, setServiceChosen] = useState(false);
  const [ableToGoBack, setAbleToGoBack] = useState(false);
  const [finishBookingStep, setFinishBookingStep] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "0befd6bd-6cee-48d1-a7dd-b42858b7e2e8";
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    }
  }, []);

  return (
    <>
      <Layout
        bookingActive={bookingActive}
        serviceChosen={serviceChosen}
        ableToGoBack={ableToGoBack}
        finishBookingStep={finishBookingStep}
        setFinishBookingStep={setFinishBookingStep}
        isHome
      >
        <Seo title="Bilda Group" />
        <Booking
          setBookingActive={setBookingActive}
          firstStep={1}
          setServiceChosen={setServiceChosen}
          setAbleToGoBack={setAbleToGoBack}
          finishBookingStep={finishBookingStep}
          setFinishBookingStep={setFinishBookingStep}
        />
        {!bookingActive && (
          <>
            {/* <Iframe1 /> */}
            <Instructions
              bg
              text1="Välj den typ av städning du önskar få hjälp med och ange hur stor din bostad är. "
              text2="Se pris och boka ett kostnadsfritt startmöte den tid som passar dig eller skicka bokningsförfrågan för våra andra tjänster."
              text3="Vi går igenom städupplägg tillsammans. Därefter kommer din städare till dig vid önskad tidpunkt och du får njuta av ett rent och snyggt hem. "
            />
            {/* <GradeSection /> */}
          </>
        )}
      </Layout>
    </>
  );
};

export default IndexPage;
